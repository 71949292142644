var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && _vm.eventos)?_c('v-card',[_c('v-card-title',{staticClass:"lightgray"},[_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"append-icon":"search","label":_vm.$tc('global.buscar'),"clearable":"","outlined":"","solo":"","flat":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"toolbar--text ml-6",staticStyle:{"background-color":"#ffffff !important"},attrs:{"outlined":"","height":"40px"},on:{"click":function($event){_vm.display = !_vm.display}}},[_c('v-icon',[_vm._v("filter_list")])],1),_c('v-spacer'),(_vm.vueExpListaEventos)?[_c('v-btn',{attrs:{"dark":"","depressed":"","small":"","color":"toolbar"},on:{"click":_vm.gerarExcel}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("get_app")]),_vm._v(" Excel ")],1)]:_vm._e()],2),_c('Filtro',{attrs:{"P_cliente":"","P_classificacoes":"","P_tipos":"","P_periodo":"","P_status":"","P_campoData":"","P_campoDataArray":_vm.dataCampos,"P_statusArray":_vm.status,"display":_vm.display},on:{"update:display":function($event){_vm.display=$event}}}),_c('v-divider'),_c('v-card-text',[(_vm.eventos)?[_c('v-data-table',{staticClass:"data-tables ",class:{ 'data-tables__row-click': _vm.vueEvento },attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.eventos,"items-per-page":10,"sort-by":['created_at'],"sort-desc":true},on:{"click:row":_vm.goToEvento},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.created_at,"dd/MM/yyyy"))+" ")]}},{key:"item.agendado_data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.agendado_data,"dd/MM/yyyy"))+" ")]}},{key:"item.concluido_em",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.concluido_em,"dd/MM/yyyy"))+" ")]}},{key:"item.cliente",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.excerpt(item.cliente))+" ")]}},{key:"item.classificacao",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":item.cor,"label":"","text-color":"white"}},[_c('v-icon',{attrs:{"size":"22","left":""}},[_vm._v(_vm._s(item.icone))]),_vm._v(" "+_vm._s(item.classificacao)+" ")],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getStatus(item.status)))]}},{key:"item.feedback_reacao",fn:function(ref){
var item = ref.item;
return [(!item.feedback_reacao)?_c('v-icon',[_vm._v(" face ")]):_vm._e(),(item.feedback_reacao == 1)?_c('v-icon',{attrs:{"color":"red accent-4"}},[_vm._v(" sentiment_very_dissatisfied ")]):_vm._e(),(item.feedback_reacao == 2)?_c('v-icon',{attrs:{"color":"orange darken-4"}},[_vm._v(" sentiment_dissatisfied ")]):_vm._e(),(item.feedback_reacao == 3)?_c('v-icon',{attrs:{"color":"amber darken-3"}},[_vm._v(" sentiment_satisfied ")]):_vm._e(),(item.feedback_reacao == 4)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(" sentiment_very_satisfied ")]):_vm._e(),(item.feedback_reacao == 5)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" favorite ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"top":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(item.status != 3 && item.status != -1 && _vm.vueEditEvento)?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.editEvento(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc("botoes.editar")))])],1):_vm._e(),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mood")])],1),_c('v-list-item-title',[_vm._v("Feedback")])],1)],1)],1)]}}],null,false,3073260998)})]:_vm._e()],2)],1):_c('v-card',{staticClass:"pa-8"},[_c('Carregando')],1)}
var staticRenderFns = []

export { render, staticRenderFns }