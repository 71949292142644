<template>
  <v-card v-if="!loading && eventos">
    <v-card-title class="lightgray">
      <v-text-field
        style="max-width: 400px;"
        v-model="search"
        append-icon="search"
        :label="$tc('global.buscar')"
        clearable
        outlined
        solo
        flat
        dense
        hide-details
      ></v-text-field>
      <v-btn
        style="background-color: #ffffff !important;"
        outlined
        class="toolbar--text ml-6"
        height="40px"
        @click="display = !display"
      >
        <v-icon>filter_list</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <template v-if="vueExpListaEventos">
        <v-btn dark depressed small color="toolbar" @click="gerarExcel">
          <v-icon left>get_app</v-icon>
          Excel
        </v-btn>
      </template>
    </v-card-title>
    <Filtro
      P_cliente
      P_classificacoes
      P_tipos
      P_periodo
      P_status
      P_campoData
      :P_campoDataArray="dataCampos"
      :P_statusArray="status"
      :display.sync="display"
    />
    <!-- P_evento_agendado
      P_evento_concluido -->
    <v-divider></v-divider>
    <v-card-text>
      <template v-if="eventos">
        <v-data-table
          :headers="headers"
          :search="search"
          :items="eventos"
          :items-per-page="10"
          :sort-by="['created_at']"
          :sort-desc="true"
          class="data-tables "
          :class="{ 'data-tables__row-click': vueEvento }"
          @click:row="goToEvento"
        >
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.agendado_data="{ item }">
            {{ item.agendado_data | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.concluido_em="{ item }">
            {{ item.concluido_em | dateFormat("dd/MM/yyyy") }}
          </template>

          <template v-slot:item.cliente="{ item }">
            {{ excerpt(item.cliente) }}
          </template>

          <template v-slot:item.classificacao="{ item }">
            <v-chip
              class="ma-2"
              small
              :color="item.cor"
              label
              text-color="white"
            >
              <v-icon size="22" left>{{ item.icone }}</v-icon>
              {{ item.classificacao }}
            </v-chip>
          </template>

          <template v-slot:item.status="{ item }">{{
            getStatus(item.status)
          }}</template>

          <template v-slot:item.feedback_reacao="{ item }">
            <v-icon v-if="!item.feedback_reacao">
              face
            </v-icon>

            <v-icon v-if="item.feedback_reacao == 1" color="red accent-4">
              sentiment_very_dissatisfied
            </v-icon>

            <v-icon v-if="item.feedback_reacao == 2" color="orange darken-4">
              sentiment_dissatisfied
            </v-icon>

            <v-icon v-if="item.feedback_reacao == 3" color="amber darken-3">
              sentiment_satisfied
            </v-icon>

            <v-icon v-if="item.feedback_reacao == 4" color="green darken-2">
              sentiment_very_satisfied
            </v-icon>

            <v-icon v-if="item.feedback_reacao == 5" color="error">
              favorite
            </v-icon>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu top left offset-x>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  @click.stop="editEvento(item)"
                  v-if="item.status != 3 && item.status != -1 && vueEditEvento"
                >
                  <v-list-item-icon>
                    <v-icon>edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{
                    $tc("botoes.editar")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mood</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Feedback</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </template>
    </v-card-text>
  </v-card>
  <v-card v-else class="pa-8">
    <Carregando />
  </v-card>
</template>

<script>
import { excelDownload } from "@/helpers/excel.js";
import { getEventos } from "@/services/api/eventos.api.js";
import { mapActions, mapState, mapGetters } from "vuex";
import { format, parseISO } from "date-fns";

export default {
  name: "ListaEventos",
  components: {
    Filtro: () => import("@/components/global/Filtro.vue"),
  },
  data: () => ({
    loading: false,
    display: false,
    eventos: null,
    search: "",
  }),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueEvento() {
      return this.getAccess("vueEvento", "visualizar");
    },
    vueEditEvento() {
      return this.getAccess("vueEvento", "editar");
    },
    vueExpListaEventos() {
      return this.getAccess("vueListaEventos", "exportar");
    },
    ...mapState("Eventos", {
      status: (state) => state.status,
    }),
    url() {
      let queryString = "";
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }
      return `?${queryString}`;
    },
    headers() {
      return [
        {
          text: this.$tc("global.data"),
          value: "created_at",
        },
        {
          text: "agendado",
          value: "agendado_data",
        },
        {
          text: "concluido",
          value: "concluido_em",
        },
        {
          text: this.$tc("eventos.protocolo"),
          value: "protocolo",
        },
        {
          text: this.$tc("pages.cliente"),
          value: "cliente",
        },
        {
          text: this.$tc("pages.classificacao"),
          value: "classificacao",
        },
        {
          text: this.$tc("global.tipo"),
          value: "tipoevento",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Feedback",
          value: "feedback_reacao",
        },
        { text: "", value: "actions", sortable: false },
      ];
    },
    dataCampos() {
      return [
        {
          text: "Data Agendamento",
          value: "agendado_data",
        },
        {
          text: "Data Concluido",
          value: "concluido_em",
        }
      ]
    }
  },
  watch: {
    url() {
      if (this.$route.path === "/eventos") {
        this.buscaEventos();
      }
    },
    $route() {
      if (this.$route.path === "/eventos") {
        this.buscaEventos();
      }
    },
  },
  methods: {
    ...mapActions("Eventos", {
      reset: "reset",
    }),
    buscaEventos() {
      this.$Progress.start();

      getEventos(this.url)
        .then((response) => {
          this.eventos = response;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$Progress.fail();
          console.log(error);
        });
    },
    excerpt(text) {
      if (!text) return;
      if (text.length > 25) {
        return text.substr(0, 25) + "...";
      } else {
        return text;
      }
    },
    getOrigen(id) {
      if (this.origens) {
        let find = this.origens.find((item) => item.id === id);
        if (find) {
          return find.descricao;
        }
      }
    },
    getStatus(id) {
      if (this.status) {
        let find = this.status.find((item) => item.value === id);
        if (find) {
          return find.text;
        }
      }
    },
    editEvento(item) {
      this.$store.commit("UPDATE_DYNAMICCRUMB", item.titulo);
      this.$router.push({
        name: "EventoEditar",
        params: { eventoId: item.id },
      });
    },
    baixaEvento(item) {
      this.$store.commit("UPDATE_DYNAMICCRUMB", item.titulo);
      this.$router.push({
        name: "EventoBaixa",
        params: { eventoId: item.id },
      });
    },
    goToEvento(item) {
      if (this.vueEvento) {
        this.$router.push({
          path: `eventos/${item.id}`,
        });
      }
    },
    gerarExcel() {
      const tHeader = [
        "Data Registro",
        "Data Agendado",
        "Data Concluido",
        "Protocolo",
        "Cliente",
        "Descricao",
        "Classificacao",
        "Tipo",
        "Status",
      ];
      const filterVal = [
        "created_at",
        "agendado_data",
        "concluido_em",
        "protocolo",
        "cliente",
        "descricao",
        "classificacao",
        "tipoevento",
        "status",
      ];
      const list = [];
      list.forEach((item) => {
        let evento = { ...item };
        evento.created_at = format(parseISO(evento.created_at), "dd/MM/yyyy");
        evento.agendado_data = format(
          parseISO(evento.agendado_data),
          "dd/MM/yyyy"
        );
        evento.concluido_em = format(
          parseISO(evento.concluido_em),
          "dd/MM/yyyy"
        );

        if (evento.status === -1) {
          evento.status = "Cancelado";
        }
        if (evento.status === 0) {
          evento.status = "Pendente";
        }
        if (evento.status === 1) {
          evento.status = "Agendado";
        }
        if (evento.status === 2) {
          evento.status = "Em Andamento";
        }
        if (evento.status === 3) {
          evento.status = "Concluido";
        }
      });
      const now = this.$moment().format("DD-MM-YYYY");
      const hrs = this.$moment().format("hh");
      const mm = this.$moment().format("mm");
      const ss = this.$moment().format("ss");
      const filename = `TOCS-Eventos${now}_${hrs}hrs_${mm}m_${ss}s`;
      this.$Progress.start();
      this.loading = true;
      excelDownload(tHeader, filterVal, list, filename).then(() => {
        this.$Progress.finish();
        this.loading = false;
      });
    },
  },
  beforeDestroy() {
    this.reset();
  },
  updated() {},
  created() {
    this.buscaEventos();
  },
};
</script>

<style scoped lang="scss"></style>
